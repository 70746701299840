@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@core/scss/angular/libs/datatables.component.scss";
// @import "~ckeditor5/dist/ckeditor5.css";

.cke_notifications_area {
  display: none !important;
}

.no-boxshadow {
  &:hover {
    box-shadow: none !important;
  }
}

.no-dropdown-toggle-icon::after {
  display: none !important;
}

.dropdown-menu-customized {
  z-index: 9999 !important;

  .dropdown-item {
    display: flex;
    gap: 6px;
    align-items: center;

    box-shadow: none;
    margin: 6px;
    border-radius: 6px;

    &:hover {
      background-color: #dceaff;
      color: #005df5;
    }
  }
}

.auto-grow-options {
  .ng-option {
    white-space: normal !important;
  }
}
