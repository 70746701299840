@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?28756587');
  src: url('../font/fontello.eot?28756587#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?28756587') format('woff2'),
       url('../font/fontello.woff?28756587') format('woff'),
       url('../font/fontello.ttf?28756587') format('truetype'),
       url('../font/fontello.svg?28756587#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?28756587#fontello') format('svg');
  }
}
*/
[class^="custom-icon-"]:before, [class*=" custom-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  /* margin-right: .2em; */
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* margin-left: .2em; */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.custom-icon-sign-factorial:before { content: '\e800'; } /* '' */
.custom-icon-sign-lemniscate:before { content: '\e801'; } /* '' */
.custom-icon-sign-minus-circle:before { content: '\e802'; } /* '' */
.custom-icon-sign-minus:before { content: '\e803'; } /* '' */
.custom-icon-sign-percent:before { content: '\e804'; } /* '' */
.custom-icon-sign-pi:before { content: '\e805'; } /* '' */
.custom-icon-sign-plus-circle:before { content: '\e806'; } /* '' */
.custom-icon-sign-plus-minus:before { content: '\e807'; } /* '' */
.custom-icon-sign-plus:before { content: '\e808'; } /* '' */
.custom-icon-calculator:before { content: '\e809'; } /* '' */
.custom-icon-number-0-square:before { content: '\e80a'; } /* '' */
.custom-icon-number-1-square:before { content: '\e80b'; } /* '' */
.custom-icon-sign-times-circle:before { content: '\e80c'; } /* '' */
.custom-icon-sign-times-square:before { content: '\e80d'; } /* '' */
.custom-icon-custom-4:before { content: '\e80e'; } /* '' */
.custom-icon-custom-5:before { content: '\e80f'; } /* '' */
.custom-icon-custom-6:before { content: '\e810'; } /* '' */
.custom-icon-custom-7:before { content: '\e811'; } /* '' */
.custom-icon-custom-8:before { content: '\e812'; } /* '' */
.custom-icon-custom-9:before { content: '\e813'; } /* '' */
.custom-icon-sign-division-circle:before { content: '\e814'; } /* '' */
.custom-icon-sign-division-square:before { content: '\e815'; } /* '' */
.custom-icon-sign-plus-square:before { content: '\e816'; } /* '' */
.custom-icon-sign-minus-square:before { content: '\e817'; } /* '' */
.custom-icon-brand-whatsapp:before { content: '\e818'; } /* '' */
.custom-icon-custom-1:before { content: '\e819'; } /* '' */
.custom-icon-custom-2:before { content: '\e81a'; } /* '' */
.custom-icon-custom-3:before { content: '\e81b'; } /* '' */
.custom-icon-hierarquia:before { content: '\e81c'; } /* '' */
.custom-icon-matricula:before { content: '\e81d'; } /* '' */
.custom-icon-revisao-de-ponto-1:before { content: '\e81e'; } /* '' */
.custom-icon-revisao-ponto-2:before { content: '\e81f'; } /* '' */
.custom-icon-turno-1:before { content: '\e820'; } /* '' */
.custom-icon-ponto:before { content: '\e821'; } /* '' */
.custom-icon-cargos:before { content: '\e822'; } /* '' */
.custom-icon-candidatos:before { content: '\e823'; } /* '' */
.custom-icon-contracheque-1:before { content: '\e824'; } /* '' */
.custom-icon-gestao-de-colaboradores:before { content: '\e825'; } /* '' */
.custom-icon-gestao-de-ferias:before { content: '\e826'; } /* '' */
.custom-icon-imposto-de-renda-1:before { content: '\e827'; } /* '' */
.custom-icon-imposto-de-renda-3:before { content: '\e828'; } /* '' */
.custom-icon-informe-de-rendimentos:before { content: '\e829'; } /* '' */
.custom-icon-premiacao-1:before { content: '\e82a'; } /* '' */
.custom-icon-premiacao-2:before { content: '\e82b'; } /* '' */
.custom-icon-recibo-de-ferias-1:before { content: '\e82c'; } /* '' */
.custom-icon-relatorio-1:before { content: '\e82d'; } /* '' */
.custom-icon-turno-2:before { content: '\e82e'; } /* '' */
.custom-icon-imposto-de-renda-2:before { content: '\e82f'; } /* '' */
.custom-icon-arrow-left-2:before { content: '\e830'; } /* '' */
.custom-icon-arrow-right-1:before { content: '\e831'; } /* '' */
.custom-icon-arrow-up-1:before { content: '\e832'; } /* '' */
.custom-icon-contracheque-2:before { content: '\e833'; } /* '' */
.custom-icon-centro-de-custos-1:before { content: '\e834'; } /* '' */
.custom-icon-recibo-ferias-2:before { content: '\e835'; } /* '' */
.custom-icon-relatorio-2:before { content: '\e836'; } /* '' */
.custom-icon-banco-de-horas:before { content: '\e837'; } /* '' */
.custom-icon-centro-de-custos-2:before { content: '\e838'; } /* '' */
.custom-icon-dashboard-1:before { content: '\e839'; } /* '' */
.custom-icon-dashboard-2:before { content: '\e83a'; } /* '' */
.custom-icon-entrevista-de-delisgamento-1:before { content: '\e83b'; } /* '' */
.custom-icon-entrevista-de-desligamento-2:before { content: '\e83c'; } /* '' */
.custom-icon-ferias:before { content: '\e83d'; } /* '' */
.custom-icon-arrow-down-1:before { content: '\e83e'; } /* '' */
.custom-icon-arrow-left-1:before { content: '\e83f'; } /* '' */
.custom-icon-arrow-top-left-1:before { content: '\e840'; } /* '' */
.custom-icon-arrow-top-left-2:before { content: '\e841'; } /* '' */
.custom-icon-arrow-top-left-3-square:before { content: '\e842'; } /* '' */
.custom-icon-arrow-top-right-1:before { content: '\e843'; } /* '' */
.custom-icon-arrow-top-right-2:before { content: '\e844'; } /* '' */
.custom-icon-arrow-up-2:before { content: '\e845'; } /* '' */
.custom-icon-arrow-up-3-square:before { content: '\e846'; } /* '' */
.custom-icon-arrow-up-4-square:before { content: '\e847'; } /* '' */
.custom-icon-arrow-up-5-circle:before { content: '\e848'; } /* '' */
.custom-icon-arrow-up-6-circle:before { content: '\e849'; } /* '' */
.custom-icon-arrow-left-4-square:before { content: '\e84a'; } /* '' */
.custom-icon-arrow-left-5-circle:before { content: '\e84b'; } /* '' */
.custom-icon-arrow-left-6-circle:before { content: '\e84c'; } /* '' */
.custom-icon-arrow-right--2:before { content: '\e84d'; } /* '' */
.custom-icon-arrow-right-2:before { content: '\e84e'; } /* '' */
.custom-icon-arrow-right-3-square:before { content: '\e84f'; } /* '' */
.custom-icon-arrow-right-4-square:before { content: '\e850'; } /* '' */
.custom-icon-arrow-right-5-circle:before { content: '\e851'; } /* '' */
.custom-icon-arrow-right-6-circle:before { content: '\e852'; } /* '' */
.custom-icon-arrow-bottom-right-1:before { content: '\e853'; } /* '' */
.custom-icon-arrow-down-2:before { content: '\e854'; } /* '' */
.custom-icon-arrow-down-3-square:before { content: '\e855'; } /* '' */
.custom-icon-arrow-down-4-square:before { content: '\e856'; } /* '' */
.custom-icon-arrow-down-5-circle:before { content: '\e857'; } /* '' */
.custom-icon-arrow-down-6-circle:before { content: '\e858'; } /* '' */
.custom-icon-arrow-left-3-square:before { content: '\e859'; } /* '' */
.custom-icon-arrow-bottom-left-1:before { content: '\e85a'; } /* '' */
.custom-icon-arrow-bottom-left-2:before { content: '\e85b'; } /* '' */
.custom-icon-cloud-no:before { content: '\e85c'; } /* '' */
.custom-icon-cloud-off:before { content: '\e85d'; } /* '' */
.custom-icon-cloud-upload:before { content: '\e85e'; } /* '' */
.custom-icon-cloud:before { content: '\e85f'; } /* '' */
.custom-icon-file-add:before { content: '\e860'; } /* '' */
.custom-icon-file-audio:before { content: '\e861'; } /* '' */
.custom-icon-file-check:before { content: '\e862'; } /* '' */
.custom-icon-file-close:before { content: '\e863'; } /* '' */
.custom-icon-file-document:before { content: '\e864'; } /* '' */
.custom-icon-file-image:before { content: '\e865'; } /* '' */
.custom-icon-file-remove:before { content: '\e866'; } /* '' */
.custom-icon-file-video:before { content: '\e867'; } /* '' */
.custom-icon-file:before { content: '\e868'; } /* '' */
.custom-icon-arrow-bottom-left-3-square:before { content: '\e869'; } /* '' */
.custom-icon-arrow-bottom-left-4-square:before { content: '\e86a'; } /* '' */
.custom-icon-arrow-bottom-left-5-circle:before { content: '\e86b'; } /* '' */
.custom-icon-arrow-bottom-left-6-circle:before { content: '\e86c'; } /* '' */
.custom-icon-arrow-bottom-right-3-square:before { content: '\e86d'; } /* '' */
.custom-icon-arrow-bottom-right-4-square:before { content: '\e86e'; } /* '' */
.custom-icon-arrow-bottom-right-5-circle:before { content: '\e86f'; } /* '' */
.custom-icon-arrow-bottom-right-6-circle:before { content: '\e870'; } /* '' */
.custom-icon-arrow-bottom-up-4-square:before { content: '\e871'; } /* '' */
.custom-icon-arrow-top-left-4-square:before { content: '\e872'; } /* '' */
.custom-icon-arrow-top-left-5-circle:before { content: '\e873'; } /* '' */
.custom-icon-arrow-top-left-6-circle:before { content: '\e874'; } /* '' */
.custom-icon-arrow-top-right-3-square:before { content: '\e875'; } /* '' */
.custom-icon-arrow-top-right-5-circle:before { content: '\e876'; } /* '' */
.custom-icon-arrow-top-right-6-circle:before { content: '\e877'; } /* '' */
.custom-icon-cloud-download:before { content: '\e878'; } /* '' */
.custom-icon-playlist:before { content: '\e879'; } /* '' */
.custom-icon-upload:before { content: '\e87a'; } /* '' */
.custom-icon-folder-image:before { content: '\e87b'; } /* '' */
.custom-icon-folder-music:before { content: '\e87c'; } /* '' */
.custom-icon-folder-remove:before { content: '\e87d'; } /* '' */
.custom-icon-folder-video:before { content: '\e87e'; } /* '' */
.custom-icon-folder:before { content: '\e87f'; } /* '' */
.custom-icon-microphone-off:before { content: '\e880'; } /* '' */
.custom-icon-microphone:before { content: '\e881'; } /* '' */
.custom-icon-music-1:before { content: '\e882'; } /* '' */
.custom-icon-music-2:before { content: '\e883'; } /* '' */
.custom-icon-player-end:before { content: '\e884'; } /* '' */
.custom-icon-player-next:before { content: '\e885'; } /* '' */
.custom-icon-player-pause:before { content: '\e886'; } /* '' */
.custom-icon-player-play:before { content: '\e887'; } /* '' */
.custom-icon-player-previous:before { content: '\e888'; } /* '' */
.custom-icon-player-start:before { content: '\e889'; } /* '' */
.custom-icon-player-stop:before { content: '\e88a'; } /* '' */
.custom-icon-attachment:before { content: '\e88b'; } /* '' */
.custom-icon-cloud-add:before { content: '\e88c'; } /* '' */
.custom-icon-cloud-clock:before { content: '\e88d'; } /* '' */
.custom-icon-cloud-error:before { content: '\e88e'; } /* '' */
.custom-icon-cloud-remove:before { content: '\e88f'; } /* '' */
.custom-icon-cloud-yes:before { content: '\e890'; } /* '' */
.custom-icon-copy:before { content: '\e891'; } /* '' */
.custom-icon-download:before { content: '\e892'; } /* '' */
.custom-icon-folder-add:before { content: '\e893'; } /* '' */
.custom-icon-folder-check:before { content: '\e894'; } /* '' */
.custom-icon-folder-close:before { content: '\e895'; } /* '' */
.custom-icon-folder-document:before { content: '\e896'; } /* '' */
.custom-icon-profile-circle:before { content: '\e897'; } /* '' */
.custom-icon-profile:before { content: '\e898'; } /* '' */
.custom-icon-send:before { content: '\e899'; } /* '' */
.custom-icon-share-1:before { content: '\e89a'; } /* '' */
.custom-icon-share-2:before { content: '\e89b'; } /* '' */
.custom-icon-volume-down:before { content: '\e89c'; } /* '' */
.custom-icon-volume-off:before { content: '\e89d'; } /* '' */
.custom-icon-volume-up:before { content: '\e89e'; } /* '' */
.custom-icon-comment-remove:before { content: '\e89f'; } /* '' */
.custom-icon-comment:before { content: '\e8a0'; } /* '' */
.custom-icon-dislike:before { content: '\e8a1'; } /* '' */
.custom-icon-email:before { content: '\e8a2'; } /* '' */
.custom-icon-headphone:before { content: '\e8a3'; } /* '' */
.custom-icon-like:before { content: '\e8a4'; } /* '' */
.custom-icon-music-album:before { content: '\e8a5'; } /* '' */
.custom-icon-music-artist:before { content: '\e8a6'; } /* '' */
.custom-icon-phone-off:before { content: '\e8a7'; } /* '' */
.custom-icon-phone:before { content: '\e8a8'; } /* '' */
.custom-icon-play-circle:before { content: '\e8a9'; } /* '' */
.custom-icon-playlist-repeat-list:before { content: '\e8aa'; } /* '' */
.custom-icon-playlist-repeat-song:before { content: '\e8ab'; } /* '' */
.custom-icon-playlist-shuffle:before { content: '\e8ac'; } /* '' */
.custom-icon-camera-image:before { content: '\e8ad'; } /* '' */
.custom-icon-camera-video:before { content: '\e8ae'; } /* '' */
.custom-icon-comment-add:before { content: '\e8af'; } /* '' */
.custom-icon-comment-check:before { content: '\e8b0'; } /* '' */
.custom-icon-comment-close:before { content: '\e8b1'; } /* '' */
.custom-icon-comment-dots:before { content: '\e8b2'; } /* '' */
.custom-icon-information-square:before { content: '\e8b3'; } /* '' */
.custom-icon-invoice:before { content: '\e8b4'; } /* '' */
.custom-icon-question-mark-circle:before { content: '\e8b5'; } /* '' */
.custom-icon-question-mark-square:before { content: '\e8b6'; } /* '' */
.custom-icon-screen-full:before { content: '\e8b7'; } /* '' */
.custom-icon-screen-normal:before { content: '\e8b8'; } /* '' */
.custom-icon-shopping-bag:before { content: '\e8b9'; } /* '' */
.custom-icon-shopping-card-add:before { content: '\e8ba'; } /* '' */
.custom-icon-shopping-card-remove:before { content: '\e8bb'; } /* '' */
.custom-icon-shopping-card:before { content: '\e8bc'; } /* '' */
.custom-icon-store:before { content: '\e8bd'; } /* '' */
.custom-icon-ticket:before { content: '\e8be'; } /* '' */
.custom-icon-badge:before { content: '\e8bf'; } /* '' */
.custom-icon-box:before { content: '\e8c0'; } /* '' */
.custom-icon-check-circle-1:before { content: '\e8c1'; } /* '' */
.custom-icon-check-circle-2:before { content: '\e8c2'; } /* '' */
.custom-icon-check:before { content: '\e8c3'; } /* '' */
.custom-icon-cheque:before { content: '\e8c4'; } /* '' */
.custom-icon-close-circle-1:before { content: '\e8c5'; } /* '' */
.custom-icon-close-circle-2:before { content: '\e8c6'; } /* '' */
.custom-icon-close-square:before { content: '\e8c7'; } /* '' */
.custom-icon-close:before { content: '\e8c8'; } /* '' */
.custom-icon-credit-card:before { content: '\e8c9'; } /* '' */
.custom-icon-delivery-fast:before { content: '\e8ca'; } /* '' */
.custom-icon-delivery-free-:before { content: '\e8cb'; } /* '' */
.custom-icon-delivery:before { content: '\e8cc'; } /* '' */
.custom-icon-discount:before { content: '\e8cd'; } /* '' */
.custom-icon-information-circle:before { content: '\e8ce'; } /* '' */
.custom-icon-attention-circle:before { content: '\e8cf'; } /* '' */
.custom-icon-attention-square:before { content: '\e8d0'; } /* '' */
.custom-icon-restart:before { content: '\e8d1'; } /* '' */
.custom-icon-sorting-center:before { content: '\e8d2'; } /* '' */
.custom-icon-sorting-left:before { content: '\e8d3'; } /* '' */
.custom-icon-sorting-right:before { content: '\e8d4'; } /* '' */
.custom-icon-swap:before { content: '\e8d5'; } /* '' */
.custom-icon-synchronize:before { content: '\e8d6'; } /* '' */
.custom-icon-type:before { content: '\e8d7'; } /* '' */
.custom-icon-unavailable:before { content: '\e8d8'; } /* '' */
.custom-icon-history:before { content: '\e8d9'; } /* '' */
.custom-icon-link-external:before { content: '\e8da'; } /* '' */
.custom-icon-link:before { content: '\e8db'; } /* '' */
.custom-icon-menu-burger-horizontal:before { content: '\e8dc'; } /* '' */
.custom-icon-menu-burger-vertical:before { content: '\e8dd'; } /* '' */
.custom-icon-menu-kebab-horizontal-circle:before { content: '\e8de'; } /* '' */
.custom-icon-menu-kebab-horizontal-square:before { content: '\e8df'; } /* '' */
.custom-icon-menu-kebab-horizontal:before { content: '\e8e0'; } /* '' */
.custom-icon-menu-kebab-vertical-circle:before { content: '\e8e1'; } /* '' */
.custom-icon-menu-kebab-vertical-square:before { content: '\e8e2'; } /* '' */
.custom-icon-menu-kebab-vertical:before { content: '\e8e3'; } /* '' */
.custom-icon-move:before { content: '\e8e4'; } /* '' */
.custom-icon-backspace:before { content: '\e8e5'; } /* '' */
.custom-icon-bluetooth:before { content: '\e8e6'; } /* '' */
.custom-icon-check-square:before { content: '\e8e7'; } /* '' */
.custom-icon-compare:before { content: '\e8e8'; } /* '' */
.custom-icon-do-redo:before { content: '\e8e9'; } /* '' */
.custom-icon-do-undo:before { content: '\e8ea'; } /* '' */
.custom-icon-enter:before { content: '\e8eb'; } /* '' */
.custom-icon-exit:before { content: '\e8ec'; } /* '' */
.custom-icon-funnel:before { content: '\e8ed'; } /* '' */
.custom-icon-news:before { content: '\e8ee'; } /* '' */
.custom-icon-pen:before { content: '\e8ef'; } /* '' */
.custom-icon-search:before { content: '\e8f0'; } /* '' */
.custom-icon-star-off:before { content: '\e8f1'; } /* '' */
.custom-icon-star:before { content: '\e8f2'; } /* '' */
.custom-icon-zoom-in:before { content: '\e8f3'; } /* '' */
.custom-icon-zoom-out:before { content: '\e8f4'; } /* '' */
.custom-icon-heart-off:before { content: '\e8f5'; } /* '' */
.custom-icon-heart:before { content: '\e8f6'; } /* '' */
.custom-icon-home:before { content: '\e8f7'; } /* '' */
.custom-icon-lightning-1:before { content: '\e8f8'; } /* '' */
.custom-icon-lightning-2:before { content: '\e8f9'; } /* '' */
.custom-icon-location-pin-off:before { content: '\e8fa'; } /* '' */
.custom-icon-location-pin:before { content: '\e8fb'; } /* '' */
.custom-icon-mouse:before { content: '\e8fc'; } /* '' */
.custom-icon-eye:before { content: '\e8fd'; } /* '' */
.custom-icon-frame:before { content: '\e8fe'; } /* '' */
.custom-icon-gift:before { content: '\e8ff'; } /* '' */
.custom-icon-3d:before { content: '\e900'; } /* '' */
.custom-icon-apps:before { content: '\e901'; } /* '' */
.custom-icon-bookmark-off:before { content: '\e902'; } /* '' */
.custom-icon-bookmark:before { content: '\e903'; } /* '' */
.custom-icon-category:before { content: '\e904'; } /* '' */
.custom-icon-clock:before { content: '\e905'; } /* '' */
.custom-icon-component:before { content: '\e906'; } /* '' */
.custom-icon-cursor:before { content: '\e907'; } /* '' */
.custom-icon-discover:before { content: '\e908'; } /* '' */
.custom-icon-edit:before { content: '\e909'; } /* '' */
.custom-icon-eye-off:before { content: '\e90a'; } /* '' */
.custom-icon-winking-face:before { content: '\e90b'; } /* '' */
.custom-icon-settings:before { content: '\e90c'; } /* '' */
.custom-icon-shield-no:before { content: '\e90d'; } /* '' */
.custom-icon-shield-off:before { content: '\e90e'; } /* '' */
.custom-icon-shield-yes:before { content: '\e90f'; } /* '' */
.custom-icon-shield:before { content: '\e910'; } /* '' */
.custom-icon-slightly-smiling-face:before { content: '\e911'; } /* '' */
.custom-icon-trash-simple:before { content: '\e912'; } /* '' */
.custom-icon-trash:before { content: '\e913'; } /* '' */
.custom-icon-flag:before { content: '\e914'; } /* '' */
.custom-icon-frowning-face:before { content: '\e915'; } /* '' */
.custom-icon-location:before { content: '\e916'; } /* '' */
.custom-icon-lock-off:before { content: '\e917'; } /* '' */
.custom-icon-lock:before { content: '\e918'; } /* '' */
.custom-icon-mode-dark:before { content: '\e919'; } /* '' */
.custom-icon-mode-light:before { content: '\e91a'; } /* '' */
.custom-icon-neutral-face:before { content: '\e91b'; } /* '' */
.custom-icon-notification-off:before { content: '\e91c'; } /* '' */
.custom-icon-notification:before { content: '\e91d'; } /* '' */
.custom-icon-options:before { content: '\e91e'; } /* '' */
.custom-icon-printer:before { content: '\e91f'; } /* '' */
.custom-icon-scanner:before { content: '\e920'; } /* '' */
.custom-icon-briefcase:before { content: '\e921'; } /* '' */
.custom-icon-calendar-1:before { content: '\e922'; } /* '' */
.custom-icon-calendar-2:before { content: '\e923'; } /* '' */
.custom-icon-calendar-add:before { content: '\e924'; } /* '' */
.custom-icon-calendar-remove:before { content: '\e925'; } /* '' */
.custom-icon-confused-face:before { content: '\e926'; } /* '' */
.custom-icon-expressionless-face:before { content: '\e927'; } /* '' */
.custom-icon-face-without-mouth:before { content: '\e928'; } /* '' */
.custom-icon-smiling-face:before { content: '\e929'; } /* '' */
.custom-icon-squinting-face:before { content: '\e92a'; } /* '' */
.custom-icon-upside-down-face:before { content: '\e92b'; } /* '' */
.custom-icon-number-4-circle:before { content: '\e92c'; } /* '' */
.custom-icon-number-4:before { content: '\e92d'; } /* '' */
.custom-icon-number-5-circle:before { content: '\e92e'; } /* '' */
.custom-icon-number-5:before { content: '\e92f'; } /* '' */
.custom-icon-number-6-circle:before { content: '\e930'; } /* '' */
.custom-icon-number-6:before { content: '\e931'; } /* '' */
.custom-icon-number-7-circle:before { content: '\e932'; } /* '' */
.custom-icon-number-7:before { content: '\e933'; } /* '' */
.custom-icon-number-8-circle:before { content: '\e934'; } /* '' */
.custom-icon-number-8:before { content: '\e935'; } /* '' */
.custom-icon-number-9-circle:before { content: '\e936'; } /* '' */
.custom-icon-number-9:before { content: '\e937'; } /* '' */
.custom-icon-pensive-face:before { content: '\e938'; } /* '' */
.custom-icon-relieved-face:before { content: '\e939'; } /* '' */
.custom-icon-disappointed-face:before { content: '\e93a'; } /* '' */
.custom-icon-face-with-open-mouth:before { content: '\e93b'; } /* '' */
.custom-icon-kissing-face-with-smiling-eyes:before { content: '\e93c'; } /* '' */
.custom-icon-kissing-face:before { content: '\e93d'; } /* '' */
.custom-icon-number-0-circle:before { content: '\e93e'; } /* '' */
.custom-icon-number-0:before { content: '\e93f'; } /* '' */
.custom-icon-number-1-circle:before { content: '\e940'; } /* '' */
.custom-icon-number-1:before { content: '\e941'; } /* '' */
.custom-icon-number-2-circle:before { content: '\e942'; } /* '' */
.custom-icon-number-2:before { content: '\e943'; } /* '' */
.custom-icon-number-3-circle:before { content: '\e944'; } /* '' */
.custom-icon-number-3:before { content: '\e945'; } /* '' */
.custom-icon-sign-radical:before { content: '\e946'; } /* '' */
.custom-icon-sign-times:before { content: '\e947'; } /* '' */
.custom-icon-sign-x:before { content: '\e948'; } /* '' */
.custom-icon-sign-y:before { content: '\e949'; } /* '' */
.custom-icon-trend-down:before { content: '\e94a'; } /* '' */
.custom-icon-trend-up:before { content: '\e94b'; } /* '' */
.custom-icon-number-2-square:before { content: '\e94c'; } /* '' */
.custom-icon-number-3-square:before { content: '\e94d'; } /* '' */
.custom-icon-number-4-square:before { content: '\e94e'; } /* '' */
.custom-icon-number-5-square:before { content: '\e94f'; } /* '' */
.custom-icon-number-6-square:before { content: '\e950'; } /* '' */
.custom-icon-number-7-square:before { content: '\e951'; } /* '' */
.custom-icon-number-8-square:before { content: '\e952'; } /* '' */
.custom-icon-number-9-square:before { content: '\e953'; } /* '' */
.custom-icon-sign-division-slash:before { content: '\e954'; } /* '' */
.custom-icon-sign-division:before { content: '\e955'; } /* '' */
.custom-icon-sign-equal:before { content: '\e956'; } /* '' */
.custom-icon-sign-f:before { content: '\e957'; } /* '' */
