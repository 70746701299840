/*
NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.card {
  min-height: 80vh;
}

.card-fit {
  min-height: auto;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.cursor-default {
  cursor: default;
}

.glow-success {
  box-shadow: 2px 2px 14px rgba(40, 199, 111, 0.3) !important;
}

.glow-danger {
  box-shadow: 2px 2px 14px rgba(234, 84, 85, 0.3) !important;
}

.glow-warning {
  box-shadow: 2px 2px 14px rgba(255, 159, 67, 0.3) !important;
}

.glow-info {
  box-shadow: 2px 2px 14px rgba(79, 177, 235, 0.3) !important;
}

.glow-success:hover,
.glow-danger:hover,
.glow-warning:hover,
.glow-info:hover {
  opacity: 1;
}

.transition {
  transition: all 0.2s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}
